@charset "utf-8";

// SASS style sheet */
// Palette color codes */
// Palette URL: http://paletton.com/#uid=72S0u0ki2bEbnkVcFeCmv9crK63 */

// Feel free to copy&paste color codes to your application */

// As hex codes */

// $color-primary-0: #1f4726; // Main Primary color */
// $color-primary-1: #527f5a;
// $color-primary-2: #36593c;
// $color-primary-3: #113818;
// $color-primary-4: #05250a;

// $color-secondary-1-0: #193539; // Main Secondary color (1) */
// $color-secondary-1-1: #426266;
// $color-secondary-1-2: #2c4447;
// $color-secondary-1-3: #0e292d;
// $color-secondary-1-4: #041a1d;

// $color-secondary-2-0: #5d4229; // Main Secondary color (2) */
// $color-secondary-2-1: #a7886c;
// $color-secondary-2-2: #745c46;
// $color-secondary-2-3: #492e16;
// $color-secondary-2-4: #301a06;

// $color-complement-0: #5d2c29; // Main Complement color */
// $color-complement-1: #a7706c;
// $color-complement-2: #744a46;
// $color-complement-3: #491a16;
// $color-complement-4: #300906;
// SASS style sheet */
// Palette color codes */
// Palette URL: http://paletton.com/#uid=72S0u0kg5iI8Ksbd2n1kAfjpnbm */

// Feel free to copy&paste color codes to your application */

// As hex codes */

$color-primary-0: #397243; // Main Primary color */
$color-primary-1: #7dac85;
$color-primary-2: #538c5d;
$color-primary-3: #215d2c;
$color-primary-4: #0e4518;

$color-secondary-1-0: #2e565b; // Main Secondary color (1) */
$color-secondary-1-1: #658589;
$color-secondary-1-2: #436b70;
$color-secondary-1-3: #1c454b;
$color-secondary-1-4: #0c3237;

$color-secondary-2-0: #956e4a; // Main Secondary color (2) */
$color-secondary-2-1: #e0c1a3;
$color-secondary-2-2: #b8916d;
$color-secondary-2-3: #7a512b;
$color-secondary-2-4: #5a3513;

$color-complement-0: #95504a; // Main Complement color */
$color-complement-1: #e0a8a3;
$color-complement-2: #b8726d;
$color-complement-3: #7a312b;
$color-complement-4: #5a1813;

$primary: $color-primary-3;
$link: $color-secondary-1-1;
$info: $color-secondary-2-0;
$success: $color-primary-1;
$danger: $color-complement-1;
.bm--card-equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.bm--card-equal-height .card-footer {
  margin-top: auto;
}
$card-radius: 0;
@import "node_modules/@fortawesome/fontawesome-svg-core/styles";
@import "../node_modules/bulma/bulma.sass";
/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}
